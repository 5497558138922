/* Tena layout -- category */
$tena-light-blue__color: #9dc2fc;
$tena-dark-blue__color: #1c569e;
$tena-font__color: $banda-cod-grey__color;

.cookie-status-message {
    display: none;
}

body {
    &.category-tena .p2-category-top,
    &.catalogsearch-result-index .search.results {
        grid-column: 1 / span 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
    }

    &.category-tena.page-products{
        .breadcrumbs,
        .page-title-wrapper,
        .sidebar-main{
            display: none;
        }
    }
}

div.tena-body {
    /* weird scrolling bug in IE */
    overflow-x: hidden;

    .tena-only {
        display: block;
    }

    .columns .column.main {
        display: grid;
        display: -ms-grid;
        grid-gap: 0rem;
        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr 1rem 1fr;
        -ms-grid-rows: auto 1rem auto 1rem auto 1rem auto;
        justify-content: center;
        align-items: center;
        padding: 0rem;
        .messages,
        .breadcrumbs,
        .products-grid,
        .review-container {
            grid-column: 1 / span 2;
            -ms-grid-column: 1;
            -ms-grid-column-span: 3;
        }
        .messages {
            grid-row: 1;
            -ms-grid-row: 1;
        }
        .breadcrumbs {
            margin-top: 1rem;
            grid-row: 2;
            -ms-grid-row: 3;
        }
        .p2-category-top {
            grid-row: 3;
            -ms-grid-row: 5;
        }
        .toolbar {
            grid-column: 2;
            -ms-grid-column: 3;
            grid-row: 3;
            -ms-grid-row: 5;
        }
        .products-grid {
            grid-row: 4;
            -ms-grid-row: 7;
        }
        .product.media {
            grid-column: 1;
            -ms-grid-column: 1;
        }
        .product-info-main {
            grid-column: 2;
            -ms-grid-column: 3;
        }
        .review-container {
            grid-row: 5;
            -ms-grid-row: 7;
        }
        .product.media,
        .product-info-main {
            width: auto;
            grid-row: 4;
            -ms-grid-row: 5;
        }
        .p2-category-top{
            .category-view{
                .category-description{
                    margin: 0;
                    padding: 0;
                    border-bottom: none;
                    [data-content-type="row"][data-appearance="contained"] {
                        [data-content-type="image"] {
                            margin: 0 8px 8px 8px;

                        }
                        h2 {
                            margin-left: 8px;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 36px;
                            color: $tena-dark-blue__color;
                        }
                        [data-content-type="buttons"] {
                            margin-top: 30px;
                            [data-content-type="button-item"] {
                                .pagebuilder-button-primary {
                                    background-color: $tena-dark-blue__color;
                                    color: white;
                                    font-size: 15px;
                                    line-height: 15px;
                                    font-weight: 600;
                                    padding: 0.9rem 1.5rem;
                                    -webkit-border-radius: 3px;
                                    border-radius: 3px;
                                    -webkit-transition: $tena-light-blue__color .2s ease;
                                    -o-transition: $tena-light-blue__color .2s ease;
                                    transition: $tena-light-blue__color .2s ease;

                                }
                                .pagebuilder-button-primary:hover {
                                    background-color: $tena-light-blue__color;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    .toolbar-amount {
        transform: translateX(-75%);
        margin: 0 auto 0 0;
    }
    .field.qty .control button,
    #product-addtocart-button {
        display: -ms-flexbox;
        align-items: center;
        justify-content: center;
    }

    .product-image-wrapper {
        min-height: 200px;
        .product-image-photo {
            position: relative;
        }
    }
    .page-title-wrapper{
        margin: 1rem 0;
    }
    .toolbar-products{
        .toolbar-sorter{
            background: #fff;
            border-radius: 50px;
            padding: 0.25rem 1rem;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
            transition: all 200ms ease;
            &:hover{
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
            }
            #sorter{
                cursor: pointer;
            }
        }
    }
    .page-header{
        box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
        .header.content{
            display: flex;
            display: -ms-flexbox;
            align-items: center;
            padding: 0.5rem 1rem;
            flex-wrap: wrap;
            .bandagist-picker,
            .bandagist-text,
            .prescription-link{
                display: none !important;
            }
            .tena-logo{
                img{
                    max-width: 315px;
                    width: 315px;
                    margin-left: -4rem;
                    margin-top: -0.5rem;
                }
            }
            ul.tena-menu{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 1rem;
                margin: 7px;
                li{
                    list-style-type: none;
                    a{
                        padding: 0rem 1.25rem;
                        font-size: 16px;
                        color: $tena-dark-blue__color;
                        font-weight: 600;
                    }
                }
            }
            .polarcore-header-links{
                order: 99;
                box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
                border-radius: 50px;
                padding: 0.25rem 1rem;
                transition: all 200ms ease;
                display: flex;
                &:hover{
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
                }
                .desktop-price-widget{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    min-width: auto;
                    margin-left: 0;
                    i.tena-only{
                        color: $tena-light-blue__color;
                        font-size: 34px;
                        margin-right: 1rem;
                    }
                    &__container{
                        display: block;
                        .price-subtotal{
                            display: flex;
                            .label{
                                margin-right: 0.35rem;
                            }
                        }
                    }
                    &__label{
                        font-size: 14px;
                        color: $banda-cod-grey__color;
                    }
                }
                .minicart-wrapper{
                    margin-top: 0.25rem;
                    .action.showcart{
                        display: flex;
                        flex-direction: row;
                        &:before{
                            display: none;
                        }
                        i.tena-only{
                            color: $tena-light-blue__color;
                            font-size: 34px;
                        }
                        .text{
                            display: none;
                        }
                        .counter.qty{
                            color: #ffffff;
                            position: absolute;
                            left: -7.5rem;
                            top: -0.1rem;
                            background: $tena-dark-blue__color;
                            border-radius: 5px;
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: 500;
                            width: 1.25rem;
                            height: 1.25rem;
                            .counter-number{
                                line-height: 1.25;
                                margin-top: -0.05rem;
                            }
                        }
                    }
                }
            }
            .block-search{
                min-width: auto;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                #search_mini_form {
                    position: relative;

                    input#search{
                        border: 2px solid $tena-light-blue__color;
                        border-radius: 50px;
                        min-height: 2.75rem;
                        padding-left: 1.25rem;
                        width: 400px;
                        margin: 0 auto;
                        padding-right: 1.25rem;
                        &::placeholder{
                            color: #A6A6A6;
                        }
                    }
                    .actions {
                        button.action.search {
                            position: absolute;
                            right: 1rem;
                            width: 2rem;
                            height: 2rem;
                            top: 0.3rem;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .products-grid{
        .product-item{
            .product-item-details{
                .product-item-name{
                    text-align: left;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 700;
                    color: $tena-font__color;
                }
                .price-box{
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: flex-end;
                    .old-price{
                        color: #474747;
                        margin-right: 0.25rem;
                    }
                    .special-price{
                        .price{
                            //color: $tena-discount-price__color;
                        }
                    }
                }
                .product-item-actions,
                .button-link{
                    width: 100%;
                    text-align: center;

                    .actions-primary{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }

                    .action.primary.tocart{
                        background: #fff;
                        width: 70%;
                        padding: 0.75rem;
                        margin-top: 0.75rem;
                        color: $tena-dark-blue__color;
                        border: 2px solid $tena-dark-blue__color;
                        border-radius: 3px;
                        font-size: 15px;
                        font-weight: 600;
                        box-shadow: none;
                        transition: all 200ms ease;
                        &:hover{
                            background: $tena-dark-blue__color;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    /* Tena Footer */
    .page-footer{
        background: $tena-dark-blue__color;
        margin: 0;
        .footer.content{
            background: $tena-dark-blue__color;
            padding-top: 0;
            *, p {
                color: white !important;
                font-size: 14px;
                margin-left: 0;
            }
            h2 {
                padding: 0;
                font-size: 22px;
                font-weight: bold;
            }
            a {
                text-decoration: underline;
                font-size: 14px;
                background: none;
                &:before {
                    display: none;
                }
            }
            a:hover {
                text-decoration: none;
            }
            .pagebuilder-column {
                margin: 8px;
            }
        }
    }
}

/* Tena layout -- product page */
.page-layout-tena{
    .column.main{
        width: 100%;

        .breadcrumbs{
            .item{
                &.home{
                    /* remove Banda home */
                    display: none;
                }
                /* Make tena category home */
                &[class*="category"]{
                    a{
                        color: $tena-dark-blue__color;
                    }
                }
                &:last-of-type{
                    padding-left: 0.5rem;
                }
            }
        }
        .product-info-main{
            width: 50%;
            .product-add-form{
                .box-tocart{
                    .field.qty{
                        .control{
                            button{
                                color: $tena-dark-blue__color;
                            }
                        }
                    }
                    .actions{
                        #product-addtocart-button{
                            background: $tena-dark-blue__color;
                            max-height: unset;
                            &:hover{
                                background: darken($tena-dark-blue__color, 4%);
                            }
                        }
                    }
                }
            }
        }
        .product-info-stock-sku {
            flex-direction: row;
        }
        .product.media{
            width: 48%;
            margin-top: 1rem;
        }
        .review-container{
            .review-form{
                margin: 2rem auto;
                .review-legend{
                    width: 100%;
                    span{
                        margin-right: 0.5rem;
                    }
                }
                .actions-toolbar{
                    .action.submit.primary{
                        background: #fff;
                        border: 1.5px solid $tena-dark-blue__color;
                        box-shadow: none;
                        color: $tena-dark-blue__color;
                        transition: all 200ms ease;
                        text-transform: none;
                        font-weight: 600;
                        border-radius: 3px;
                        &:hover{
                            background: $tena-dark-blue__color;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

/* Ajaxcart Tena */
.page-layout-tena{
    #ajaxcart{
        .inner{
            .success{
                background: $tena-dark-blue__color;
            }
            .cartamount{
                .amount-percent::-webkit-progress-value{
                    background-image: -webkit-linear-gradient(left, #1c569e, #1c569e);
                }
            }
            .actions{
                #button-cart{
                    background: $tena-dark-blue__color;
                    border-color: $tena-dark-blue__color;
                    border-bottom: 2px solid darken($tena-dark-blue__color, 4%);
                    &:hover{
                        background: darken($tena-dark-blue__color, 4%);
                    }
                }
            }
        }
    }
}

/* Desktop only */
@include min-screen($screen__l){
    .page-layout-tena{
        .page-header{
            .header.content{
                .tena-menu{
                    grid-column: 2 / span 3;
                }
            }
        }
        .sidebar-main{
            display: none;
        }
        .p2-category-top{
            #layered-filter-block{
                display: none;
            }
        }
        .products-grid{
            .product-item{
                width: calc(20% - 10px) !important;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        .wpcloud-root{
            .products-grid{
                .product-item{
                    &:nth-child(n+6){
                        display: none;
                    }
                }
            }
        }
    }
}

/* Tablet only */
@include screen($screen__s, $screen__m){
    .page-layout-tena{
        .header{
            &.content{
                .block.block-search{
                    grid-column: span 12;
                    grid-row: 2;
                    padding: 0;
                }
                .tena-logo{
                    grid-column: 1 / span 2;
                }
                .tena-menu{
                    grid-column: 3 / span 7;
                }
            }
        }
        .wpcloud-root{
            .products-grid{
                .product-item{
                    &:nth-child(n+4){
                        display: none;
                    }
                }
            }
        }
    }
}

/* Tablet + Mobile */
@include max-screen($screen__l){
    .page-layout-tena{
        #maincontent{
            .sidebar-main{
                padding: 0.5rem;
                .filter-title{
                    &:before{
                        display: none;
                    }
                }
                .filter{
                    .filter-current{
                        .items{
                            display: none;
                        }
                        &-subtitle{
                            padding-bottom: 0rem;
                            &:before{
                                right: 4px;
                            }
                            &:after{
                                color: $tena-dark-blue__color;
                                margin-left: 0.3rem;
                            }
                        }
                        padding-top: 0.25rem;
                        &.active{
                            padding-bottom: 0;
                            .items{
                                display: block;
                                .item{
                                    width: auto;
                                    margin-bottom: 0;
                                    margin-top: 1rem;
                                    .action.remove{
                                        display: flex;
                                        justify-content: space-around;
                                        margin-left: 0.5rem;
                                        width: 50%;
                                        max-width: 115px;
                                    }
                                }
                            }
                        }
                    }
                }
                .filter.active{
                    .filter-title{
                        &:before{
                            display: block;
                        }
                    }
                    .filter-content{
                        display: block;
                    }
                }
            }
            .product-info-main{
                width: 100%;
            }
            .product.media{
                width: 100%;
                order: -1;
            }
        }
    }
}

/* Mobile only */
@include max-screen($screen__s){
    .page-layout-tena{
        &.catalog-category-view{
            #maincontent{
                .column.main{
                    .breadcrumbs{
                        padding-left: 0.75rem;
                    }
                    .p2-category-top{
                        padding-left: 0rem;
                        .page-title{
                            padding-left: 0.75rem;
                        }
                    }
                }
            }
        }
        #maincontent{
            padding: 0;
            margin: 0;
            .columns{
                padding: 0;
                .column.main{
                    padding: 0;
                    display: flex;
                    .products{
                        margin: 0;
                    }
                    .review-container{
                        padding: 1rem;
                    }
                }
            }
        }
        .wpcloud-root{
            .products-grid{
                .product-item{
                    &:nth-child(n+3){
                        display: none;
                    }
                }
            }
        }
        .page-header{
            .header{
                &.content{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .tena-logo{
                        width: 49.9%;
                        img{
                            max-width: 245px;
                            margin-top: -1rem;
                            margin-left: -2rem;
                        }
                    }
                    .tena-menu{
                        order: 2;
                        padding-left: 0;
                        width: 100%;
                        justify-content: center;
                        li{
                            a{
                                padding: 0rem 1.15rem;
                                font-size: 16px;
                            }
                        }
                    }
                    .block-search{
                        width: 100%;
                        order: 3;
                        display: flex;
                        justify-content: flex-start;
                    }
                    .polarcore-header-links{
                        width: 45%;
                        order: 1;
                        margin-left: 5%;
                        .desktop-price-widget{
                            margin-left: 1rem;
                            i.tena-only{
                                margin-right: 0.5rem;
                                margin-left: 0.25rem;
                            }
                        }
                        .minicart-wrapper{
                            .action.showcart{
                                .counter.qty{
                                    left: -7.5rem;
                                    top: -0.1rem;
                                    position: absolute;
                                    .counter-number{
                                        line-height: 1.25;
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .page-layout-tena {
        .products-grid{
            .product-item{
                width: calc(33.33% - 10px) !important;
                margin-left: 5px;
                margin-right: 5px;
            }
        }

    }
}
